"use client"

import { Theme } from "@radix-ui/themes"

export interface ProvidersProps {
  children: React.ReactNode
}

export const ThemeProviders = ({ children }: ProvidersProps) => (
  <Theme className="h-full subpixel-antialiased">{children}</Theme>
)
