"use client"

// user_idを仕込むために@next/third-parties/googleをカスタマイズ
// https://github.com/vercel/next.js/blob/canary/packages/third-parties/src/google/ga.tsx
import { type FC } from "react"
import Script from "next/script"
import { useUser } from "@clerk/nextjs"

import { env } from "@/env.mjs"

type GoogleAnalyticsProps = {
  gaId: string
  dataLayerName?: string
}

let currDataLayerName: string | undefined = undefined

export const InfoboxAnalytics = () => {
  const gAnalyticsId = env.NEXT_PUBLIC_GOOGLE_ANALYTICS
  if (!gAnalyticsId) {
    console.warn(
      "google analytics disabled. Please set the NEXT_PUBLIC_GOOGLE_ANALYTICS in the environment variables"
    )
    return null
  }

  return <GoogleAnalytics gaId={gAnalyticsId} />
}

const GoogleAnalytics: FC<GoogleAnalyticsProps> = (props) => {
  const { gaId, dataLayerName = "dataLayer" } = props
  const { user } = useUser()

  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName
  }

  return (
    <>
      <Script
        id="_next-ga"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
      />
      <Script
        id="_next-ga-init"
        dangerouslySetInnerHTML={{
          __html: `
          window['${dataLayerName}'] = window['${dataLayerName}'] || [];
          function gtag(){window['${dataLayerName}'].push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaId}', {
            'user_id': '${user?.id}'
           });
          `,
        }}
      />
    </>
  )
}
