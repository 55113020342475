import * as React from "react"
import * as ToastPrimitives from "@radix-ui/react-toast"
import { cva } from "class-variance-authority"

import { cn, customTwMerge } from "@/lib/utils"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@/components/icons/micro"
import { Button } from "./button"
import { Typography } from "./typography"

type ToastProps = {
  description?: React.ReactNode
  title?: React.ReactNode
  variant: "normal" | "info" | "warn" | "error"
  action?: React.ReactElement<typeof ToastAction>
} & React.ComponentProps<typeof ToastPrimitives.Root>

const Toast = ({
  description,
  title,
  variant,
  action,
  className,
  ...restProps
}: ToastProps) => (
  <ToastPrimitives.Root
    className={customTwMerge(variants({ variant }), className)}
    // TODO: variant 毎の aria-live (type props) を検討する
    // see: https://www.radix-ui.com/primitives/docs/components/toast#sensitivity
    {...restProps}
  >
    <div
      className={contentsVariants({
        hasTitle: !!title,
        isNormal: variant === "normal",
      })}
    >
      {getIcon(variant)}
      {title && (
        <ToastPrimitives.Title asChild>
          <Typography as="span" variant="title-md" className="text-inherit">
            {title}
          </Typography>
        </ToastPrimitives.Title>
      )}
      <ToastPrimitives.Description asChild>
        <Typography as="span" variant="body-sm" className="text-inherit">
          {description}
        </Typography>
      </ToastPrimitives.Description>
    </div>
    {action}
    <ToastPrimitives.Close
      className="absolute right-1 top-1 p-2 text-design-tokens-object-base-mid"
      aria-label="閉じる"
    >
      <XMarkIcon className="size-4" aria-hidden="true" />
    </ToastPrimitives.Close>
  </ToastPrimitives.Root>
)

const ICON_PROPS: React.ComponentPropsWithoutRef<"svg"> = {
  className: "size-4",
  "aria-hidden": true,
}

const getIcon = (variant: ToastProps["variant"]) => {
  switch (variant) {
    case "normal":
      return null
    case "info":
      return <InformationCircleIcon {...ICON_PROPS} />
    case "warn":
      return <ExclamationTriangleIcon {...ICON_PROPS} />
    case "error":
      return <ExclamationCircleIcon {...ICON_PROPS} />
  }
}

export const variants = cva(
  "bg-design-tokens-surface-base-primary min-w-[320px] effect-shadow-layer-modal relative py-6 pl-6 pr-9 border rounded-2xl transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full",
  {
    variants: {
      variant: {
        normal:
          "text-design-tokens-object-base-high border-design-tokens-object-base-low",
        info: "text-tokens-object-accent-dim border-design-tokens-object-accent-bright",
        warn: "text-tokens-object-caution-dim border-design-tokens-object-caution-bright",
        error:
          "text-tokens-object-error-dim border-design-tokens-object-error-bright",
      },
    },
  }
)

export const contentsVariants = cva("items-center gap-2", {
  variants: {
    hasTitle: {
      true: "grid [&>span:nth-of-type(2)]:col-span-2",
      false: "flex",
    },
    isNormal: {
      // NOTE: boolean として型解決するために必要
      true: "",
    },
  },
  compoundVariants: [
    {
      isNormal: false,
      hasTitle: true,
      className: "grid-cols-[16px_1fr]",
    },
    {
      isNormal: true,
      hasTitle: true,
      className: "grid-cols-auto",
    },
    {
      isNormal: true,
      hasTitle: false,
      className: "gap-0",
    },
  ],
})

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed right-8 top-8 z-[100] flex max-h-screen w-full max-w-fit flex-col-reverse p-4",
      className
    )}
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

type ToastActionProps = React.ComponentPropsWithoutRef<
  typeof ToastPrimitives.Action
> &
  React.ComponentProps<typeof Button>

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  ToastActionProps
>(({ children, className, altText, ...props }, ref) => (
  <ToastPrimitives.Action
    asChild
    ref={ref}
    className={cn("mt-2 flex w-fit", className)}
    altText={altText}
  >
    <Button {...props} color="primary" variant="neutral" size="sm">
      {children}
    </Button>
  </ToastPrimitives.Action>
))
ToastAction.displayName = ToastPrimitives.Action.displayName

type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
  ToastAction,
  ToastProvider,
  ToastViewport,
  Toast,
  type ToastProps,
  type ToastActionElement,
}
