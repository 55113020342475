import * as React from "react"
import { Slot, Slottable } from "@radix-ui/react-slot"

import { customTwMerge } from "@/lib/utils"
import { Icon } from "@/components/icons/original"
import { buttonVariants, type ButtonVariants } from "./button-varinat"

type Props = ButtonVariants & {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  asChild?: boolean
  loading?: boolean
} & React.ComponentPropsWithoutRef<"button">

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      color,
      size,
      variant,
      disabled,
      leftIcon,
      rightIcon,
      asChild,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    const _disabled = disabled || loading

    return (
      <Comp
        className={customTwMerge(
          buttonVariants({ color, size, variant }),
          _disabled && "cursor-not-allowed opacity-40",
          variant === "fill"
            ? `typography-title-${size}`
            : `typography-body-${size}`,
          className
        )}
        ref={ref}
        disabled={_disabled}
        {...props}
      >
        {leftIcon}
        {loading ? (
          <Slottable>
            {/* NOTE: レイアウトシフトしないようコンテンツの幅を保つために opacity-0 と絶対配置を用いる */}
            <div className="opacity-0" aria-hidden>
              {children}
            </div>
            <Icon
              aria-label="ローディング"
              name="loader-circle"
              className="absolute inset-0 mx-auto animate-spin self-center"
            />
          </Slottable>
        ) : (
          <Slottable>{children}</Slottable>
        )}
        {rightIcon}
      </Comp>
    )
  }
)

Button.displayName = "Button"

export { Button }
