import { cva, type VariantProps } from "class-variance-authority"

export type ButtonVariants = VariantProps<typeof buttonVariants>

export const buttonVariants = cva(
  `inline-flex overflow-hidden items-center justify-center gap-1.5 relative before:absolute before:inset-0 before:bg-design-tokens-surface-overlay-hovered before:opacity-0 before:transition-opacity hover:before:opacity-100`,
  {
    variants: {
      variant: {
        fill: "text-design-tokens-object-base-high-inverse effect-shadow-element-button-solid hover:bg-unset",
        outline: "border",
        neutral: "effect-shadow-element-button-neutral",
        ghost: "",
      },
      color: {
        primary: "",
        secondary: "",
        destructive: "",
      },
      onlyIcon: {
        true: "",
        false: "",
      },
      size: {
        lg: "rounded-lg px-4 py-3 h-12 [&_svg]:size-5",
        md: "rounded-lg px-3 py-2.5 h-10 [&_svg]:size-4",
        sm: "rounded-md px-2.5 py-2 h-8 [&_svg]:size-4",
        xs: "rounded-md p-1.5 h-6 [&_svg]:size-3",
      },
    },
    defaultVariants: {
      color: "primary",
      onlyIcon: false,
      size: "md",
      variant: "fill",
    },
    compoundVariants: [
      {
        onlyIcon: true,
        size: "lg",
        className: "p-3 size-12 [&_svg]:size-6",
      },
      {
        onlyIcon: true,
        size: "md",
        className: "p-2.5 size-10 [&_svg]:size-5",
      },
      {
        onlyIcon: true,
        size: "sm",
        className: "p-2 size-8 [&_svg]:size-4",
      },
      {
        onlyIcon: true,
        size: "xs",
        className: "p-1.5 size-6 [&_svg]:size-3",
      },
      {
        variant: "fill",
        color: "primary",
        className: "bg-design-tokens-surface-accent-dim",
      },
      {
        variant: "fill",
        color: "primary",
        size: "lg",
        className: "bg-design-tokens-surface-accent-bright",
      },
      {
        variant: "fill",
        color: "secondary",
        className: "bg-design-tokens-surface-base-tertiary-inverse",
      },
      {
        variant: "fill",
        color: "destructive",
        className: "bg-design-tokens-surface-error-dim",
      },
      {
        variant: "fill",
        color: "destructive",
        size: "lg",
        className: "bg-design-tokens-surface-error-bright",
      },
      {
        variant: "outline",
        color: "primary",
        className:
          "text-design-tokens-object-accent-bright border-design-tokens-border-accent-bright",
      },
      {
        variant: "outline",
        color: "secondary",
        className:
          "text-design-tokens-object-base-high border-design-tokens-border-base-high",
      },
      {
        variant: "outline",
        color: "destructive",
        className:
          "text-design-tokens-object-error-bright border-design-tokens-border-error-dim",
      },
      {
        variant: "neutral",
        color: "primary",
        className:
          "bg-design-tokens-surface-base-primary text-design-tokens-object-base-high",
      },
      {
        variant: "neutral",
        color: "secondary",
        className:
          "bg-design-tokens-surface-base-secondary text-design-tokens-object-base-high",
      },
      {
        variant: "neutral",
        color: "destructive",
        className:
          "bg-design-tokens-surface-error-subtle text-design-tokens-object-error-dim",
      },
      {
        variant: "ghost",
        color: "primary",
        className: "text-design-tokens-object-base-high",
      },
      {
        variant: "ghost",
        color: "secondary",
        className: "text-design-tokens-object-base-mid",
      },
      {
        variant: "ghost",
        color: "destructive",
        className: "text-design-tokens-object-error-dim",
      },
    ],
  }
)
