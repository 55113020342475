import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  server: {
    // DATABASE_URL: z.string().min(1),
    // CLERK_SECRET_KEY: z.string().min(1),
    // STRIPE_API_KEY: z.string().min(1),
    // STRIPE_WEBHOOK_SECRET: z.string().min(1),
    // STRIPE_PRO_MONTHLY_PLAN_ID: z.string().min(1),
    // google oauthやめてるので
    // GOOGLE_CLIENT_SECRET: z.string().min(1),
    // GOOGLE_CLIENT_ID: z.string().min(1),
    // GOOGLE_REDIRECT_URL: z.string().min(1),
    NEXT_PUBLIC_APP_DOMAIN: z.string().url(),
    ANYFLOW_VENDOR_ID: z.string().min(1),
    ANYFLOW_PRIVATE_KEY: z.string().min(1),
    WEBSHARE_PROXY_SERVER_1: z.string().min(1),
    WEBSHARE_PROXY_SERVER_2: z.string().min(1),
    WEBSHARE_PROXY_SERVER_3: z.string().min(1),
    WEBSHARE_PROXY_SERVER_USERNAME: z.string().min(1),
    WEBSHARE_PROXY_SERVER_PASSWORD: z.string().min(1),
    JINJI_API_APP: z.string().min(1),
    NEWS_API_APP: z.string().min(1),
    RESEND_API_KEY: z.string().min(1),
    GROUP_COMP_API_APP: z.string().min(1),
  },
  client: {
    // NEXT_PUBLIC_APP_URL: z.string().min(1),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_ANALYTICS: z.string().min(1).optional(),
  },
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    // STRIPE_API_KEY: process.env.STRIPE_API_KEY,
    // STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    // STRIPE_PRO_MONTHLY_PLAN_ID: process.env.STRIPE_PRO_MONTHLY_PLAN_ID,
    // NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_GOOGLE_ANALYTICS: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    // GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    // GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    // GOOGLE_REDIRECT_URL: process.env.GOOGLE_REDIRECT_URL,
    NEXT_PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
    ANYFLOW_VENDOR_ID: process.env.ANYFLOW_VENDOR_ID,
    ANYFLOW_PRIVATE_KEY: process.env.ANYFLOW_PRIVATE_KEY,
    WEBSHARE_PROXY_SERVER_1: process.env.WEBSHARE_PROXY_SERVER_1,
    WEBSHARE_PROXY_SERVER_2: process.env.WEBSHARE_PROXY_SERVER_2,
    WEBSHARE_PROXY_SERVER_3: process.env.WEBSHARE_PROXY_SERVER_3,
    WEBSHARE_PROXY_SERVER_USERNAME: process.env.WEBSHARE_PROXY_SERVER_USERNAME,
    WEBSHARE_PROXY_SERVER_PASSWORD: process.env.WEBSHARE_PROXY_SERVER_PASSWORD,
    JINJI_API_APP: process.env.JINJI_API_APP,
    NEWS_API_APP: process.env.NEWS_API_APP,
    RESEND_API_KEY: process.env.RESEND_API_KEY,
    GROUP_COMP_API_APP: process.env.GROUP_COMP_API_APP,
  },
})
