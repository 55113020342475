"use client"

import { Toast, ToastProvider, ToastViewport } from "./toast"
import { useToast } from "./use-toast"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map((props) => (
        <Toast {...props} key={props.id} />
      ))}
      <ToastViewport />
    </ToastProvider>
  )
}
